import React from "react";
import "./ResourcesList.css";

import ResourceIcon03 from "../../assets/ResourceIcon03.webp";
import ResourceIcon04 from "../../assets/ResourceIcon04.webp";
import ResourceIcon05 from "../../assets/ResourceIcon05.webp";
import mixpanel from "mixpanel-browser";

export const ResourcesList = () => {

  const viewResourceClick = (event) => {
    let trackHref = event.target.href;
    const trackText = event.target.text;

    if (trackHref == null) {
      trackHref = event.target.parentNode.href;
    }

    mixpanel.track("internal_click",{
        "target_url": trackHref,
        "target_text": trackText,
        }
    );

  };

  return (
    <div className="resources-section">
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}><td><h3>Learn the Basics</h3><p>Get the background you need to understand what happens on your local school board.</p></td>
            <td className="right-align">
              <img alt="location" className="resources-table-image" src={ResourceIcon03} />
            </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>What Is a School Board?</h5>
              <p>Find out what boards do and how members are chosen.</p>  
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Where to Find Data About Your Schools</h5>
              <p>Use these resources to get test scores, graduation rates, and other vital stats.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Who Serves on a School Board?</h5>
              <p>Discover the people who sit on your school board–it might surprise you.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>What Happens at a School Board Meeting?</h5>
              <p>There are 14,000 school boards in the US. Most follow the same basic rules.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>
              <td><h3>Get Involved</h3>
                <p>Dive into the issues challenging your community, then exercise your right to raise your voice for others to hear.</p></td>
              <td className="right-align">
                <img alt="location" className="resources-table-image" src={ResourceIcon04} />
              </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>Learn the Latest About Your Board</h5>
              <p>Here’s how to find out what your board has been doing.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>5 Questions to Ask Your School Board</h5>
              <p>Everyone loves a few thought-provoking conversation starters.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>How to Write An Email to Your School Board</h5>
              <p>They want to hear from you, so share what’s on your mind.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Why Equity is Worth Fighting For</h5>
              <p>Schools can&apos;t all be the same, but they can give all students equal opportunities</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
        </table>
      </div>
      <div className="resources-table">
        <table>
          <tr>
            <th colSpan={3}>
              <td>
                <h3>Rethink High School</h3>
                <p>Discover how XQ is transforming high school education and learn the innovative ways all of us–including students–can be changemakers.</p>
              </td>
              <td className="right-align">
                <img alt="location" className="resources-table-image" src={ResourceIcon05} />
              </td>
            </th>
          </tr>
          <tr>
            <td>
              <h5>Students on the School Board</h5>
              <p>High schoolers are old enough to have a seat at the table.</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>Assess Your High School’s Strengths and Weaknesses</h5>
              <p>How can high school students make an impact?</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
          <tr>
            <td>
              <h5>How High Schools Are Rethinking High School</h5>
              <p>How can high school students make an impact?</p>
            </td>
            <td className="right-align"><a onClick={viewResourceClick} href="/">View</a></td>
          </tr>
        </table>
      </div>
    </div>
  );
};
