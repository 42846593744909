import * as React from "react";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import XQLogo from "../assets/XQ_Logo.png";
import Stack from "@mui/material/Stack";
import mixpanel from "mixpanel-browser";


document.addEventListener('click', event => {
  const linkUrl = event.target.getAttribute('href'); // Using the second argument to handle relative URLs
  const targetText = event.target.text;
  const targetURL = event.target.href;
  let hrefLead = '';

  if (linkUrl !== null) {
    hrefLead = linkUrl.charAt(0);
  }

  if (event.target.tagName == 'A' && hrefLead != '.' && hrefLead != '#' && hrefLead != '/') {
    // outbound link
    console.log('outbound click');
      mixpanel.track("outbound_click",{
      "target_url": targetURL,
      "target_text": targetText,
      }
      );

  }
});


export const HeaderNav = () => {

  const navClick = (event) => {
    let trackHref = event.target.href;
    let trackText = event.target.text;

    if (trackHref == null) {
      trackHref = event.target.parentNode.href;
    }

    if (trackText == null) {
      trackText = 'Logo';
    }

    mixpanel.track("navigation_bar_click",{
        "target_url": trackHref,
        "target_text": trackText,
        }
    );

  };

  return (
    <AppBar position="sticky" aria-label="Navigation" role="navigation">
      <Box sx={{ flexGrow: 1 }}>
        <div
          style={{
            alignItems: "center",
            padding: "8px 24px",
            gap: "8px",
            background: "#FFFFFF",
            color: "#000000",
            fontFamily: "Roobert",
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="navbar"
          >
            <Box>
              <Link className="nav-item" href="/" aria-label="Back to home" onClick={navClick}>
                <img
                  alt="logo"
                  src={XQLogo}
                  width="67px"
                  height="35px!important"
                />
              </Link>
            </Box>
          </Stack>
        </div>
      </Box>
      <Link className="nav-item" href="/" aria-label="Home" onClick={navClick}>
        Home
      </Link>
      <Link className="nav-item" href="/resources" aria-label="Resources" onClick={navClick}>
        Resources
      </Link>
    </AppBar>
  );
};
