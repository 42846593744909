import React from "react";
import "./App.css";
import Home from "./home/Home";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Results } from "./results/Results";
import { Theme } from "./styles/overrideStyles";
import { HeaderNav } from "./common/HeaderNav";
import { AnalyticsRoute } from "./common/AnalyticsRoute";
import Resources from "./about/About";
import { ApolloProvider } from "@apollo/client";
import { client } from './services/apollo';



function App() {

  // const handleNavClick = (event: { keyCode: any; }) => {
  //   console.log('A key was pressed', event.keyCode);
  // };

  return (
    <ThemeProvider theme={Theme}>
      <HeaderNav />
      <CssBaseline />
      <div className="App">
        <ApolloProvider client={client}>
          <Routes>
            <Route
              path="/"
              element={
                <AnalyticsRoute>
                  <Home />
                </AnalyticsRoute>
              }
            />
            <Route
              path="/results"
              element={
                <AnalyticsRoute>
                    <Results />
                </AnalyticsRoute>
              }
            />
            <Route
              path="/resources"
              element={
                <AnalyticsRoute>
                  <Resources />
                </AnalyticsRoute>
              }
            />
          </Routes>
        </ApolloProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
