import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const reportData =
  process.env.REACT_APP_HONEYBADGER_ENV_NAME === "production" ||
  process.env.REACT_APP_HONEYBADGER_ENV_NAME === "staging";
const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APPHONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV_NAME,
  reportData,
});

const mixpanelApiToken = process.env.REACT_APP_MIXPANEL_TOKEN || "0b743eb8e80bac705442498679033ba8";
mixpanel.init(mixpanelApiToken, {
  debug: true
});

mixpanel.register_once({
  "event_source": "School-Board Lookup Tool",
});

mixpanel.track_pageview();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </HoneybadgerErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
