import mixpanel from "mixpanel-browser";
import { BallotReadyData } from "./types/ballotReadyData";

export enum EventType {
  DistrictChangeClick = "district_change_click",
  SearchClick = "search_click",
  AccordionClick = "accordion_click",
  CopyEmailClick = "copy_email_click",
  OutboundClick = "outbound_click",
  ResultsViewed = "results_viewed",
}

export const trackResultsViewed = (results: BallotReadyData[]) => {

  const event_district_geoid = results[0].geoId;
  const event_district_name = results[0].name;

  const data = {
    hasDistrictLevelData: results.length > 0,
    numberOfDistricts: results.length,
    districtData: results.map((district) => {
      return {
        geoId: district.geoId,
        districtName: district.name,
        hasMeetingData: !!district.districtDetails?.dateFormula,
        hasElectionData: !!district.electionInfo,
        hasBoardMembers:
          !!district.boardMembers && district.boardMembers.length > 0,
        numberOfBoardMembers: district.boardMembers?.length,
        hasSuperintendent: !!district.districtDetails?.superintendentFirstName,
        hasDistrictUrl: !!district.districtDetails?.schoolBoardUrl,
        districtUrl: district.districtDetails?.schoolBoardUrl,
      };
    }),
    event_district_geoid: event_district_geoid,
    event_district_name: event_district_name,

  };
  trackEvent(EventType.ResultsViewed, data );
};

export const trackEvent = (
  eventType: EventType,
  eventProperties?: { [key: string]: any },
) => {
  if (!eventType) {
    return;
  }
  try {
    mixpanel.track(eventType, eventProperties);
  } catch (e) {
    console.error(e);
  }
};

export const trackPageView = (eventProperties?: { [key: string]: any }) => {
  try {
    mixpanel.track_pageview(eventProperties);
  } catch (e) {
    console.error(e);
  }
};
