import React from "react";
import { AccordionDisplay } from "./AccordionDisplay";
import { Stack } from "@mui/material";
import "./ElectionsAccordion.css";
import { ElectionInfo } from "../../lib/types/ballotReadyData";
import { InformationUnavailableDisplay } from "./InformationUnavailableDisplay";

interface ElectionsAccordionProps {
  electionInfo?: ElectionInfo;
  districtUrl?: string;
}

export const ElectionsAccordion = ({
  electionInfo,
  districtUrl,
}: ElectionsAccordionProps) => {
  if (electionInfo?.nextElectionDate) {
    const updatedAtDisplayDateString = new Date(
      electionInfo?.lastUpdatedAt,
    ).toLocaleDateString("en-US");

    return (
      <AccordionDisplay
        title="Upcoming Elections"
        updated={updatedAtDisplayDateString}
      >
        <ElectionAccordionInfo electionInfo={electionInfo} />
      </AccordionDisplay>
    );
  }

  return (
    <AccordionDisplay title="Upcoming Elections">
      <InformationUnavailableDisplay districtUrl={districtUrl} />
    </AccordionDisplay>
  );
};

const ElectionAccordionInfo = ({
  electionInfo,
}: {
  electionInfo: ElectionInfo;
}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const dateTimeFormat = new Intl.DateTimeFormat("en-US", options);

  const dateStringWithTime = electionInfo.nextElectionDate + "T12:00";
  const formattedDate = dateTimeFormat.format(new Date(dateStringWithTime));

  return (
    <Stack direction="column" spacing={1} className="accordion-stack">
      <div className="district-section-titles">Next election date</div>
      <p className="election-date">{formattedDate}</p>
      <p className="election-info">
        {electionInfo?.seatsUpForElection} seats up for reelection
      </p>
    </Stack>
  );
};
