import React, { useState } from "react";
import { DistrictSection } from "./sections/DistrictSection";
import "./sections/DistrictSection.css";
import { useSearchParams } from "react-router-dom";
import { useGetDistrictData } from "../hooks/districts";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./Results.css";

import { ElectionsAccordion } from "./sections/ElectionsAccordion";
import { MeetingAccordion } from "./sections/MeetingAccordion";
import { BoardMembersAccordion } from "./sections/BoardMembersAccordion";
import Backdrop from "@mui/material/Backdrop";
import { ReactComponent as LoadingIcon } from "../assets/LoadingIcon.svg";
import { BallotReadyData } from "../lib/types/ballotReadyData";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Popover,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "react-responsive";
import AnchorIcon from "@mui/icons-material/Link";

import { useResultPageEndpoint } from "../hooks/wordpressCms";
import { useHomePageEndpoint } from "../hooks/wordpressCms";

import { Footer } from "../common/Footer";
import { ResultPageConfig } from "../lib/types/cmsConfigs";
import { EventType, trackEvent, trackResultsViewed } from "../lib/analytics";
import { Link } from "../common/Link";
import { YellowSection } from "../home/sections/YellowSection";
// import { ResourcesSection } from "./sections/ResourcesSection";

import { ReactComponent as CopyIcon } from "../assets/CopyIcon.svg";
import { ReactComponent as GradCap } from "../assets/GradCap.svg";
import { GravityFormsForm } from "../generated/graphql";
import getGravityForm from "../utilities/gravity-forms";
import GravityForm from '../components/GravityForm';
import ResourceIcon from "../assets/ResourceIcon04.webp";

interface Props {
  form: GravityFormsForm;
}

const formData = await getGravityForm(1);

export const FormFields = ({ form }: Props) => {
  return (
    <GravityForm form={form} />
  );
}

export const Results = () => {

  const { loadHomePage, homePageConfig } = useHomePageEndpoint();

  const [searchParams] = useSearchParams();
  const lat = searchParams.get("lat") || "";
  const lng = searchParams.get("lng") || "";

  const { loadData, ballotReadyData, isLoading } = useGetDistrictData(lat, lng);
  const { loadResultPage, resultPageConfig } = useResultPageEndpoint();

  const [isActive, setIsActive] = useState(false);

  const handleContactUsClick = () => {
    setIsActive(!isActive);

    window.scrollBy({
      top: 300,
      behavior: "smooth",
    });

  }

  useEffect(() => {
    loadData();
    loadResultPage();
    loadHomePage();
  }, [lat, lng]);

  useEffect(() => {
    if (ballotReadyData) {
      trackResultsViewed(ballotReadyData);
    }
  }, [ballotReadyData]);

  return (
    <div className="results-root">
      <Backdrop
        sx={{ backgroundColor: "#fff", color: "#000", zIndex: 100 }}
        open={isLoading || !resultPageConfig}
      >
        <Container>
          <Container className="loading-icon-container">
            <LoadingIcon />
          </Container>
          <Typography className="loading-text">
            Finding School Board...
          </Typography>
        </Container>
      </Backdrop>

      {!isLoading && resultPageConfig && (
        <>
          <SchoolDistrictDataTable
            schoolDistricts={ballotReadyData}
            config={resultPageConfig}
          />

          {homePageConfig && (
            <>
              <Stack>
                <YellowSection config={homePageConfig} />
              </Stack>
            </>
            )}
          <Grid container className="results-grid-container">
          <Stack className="form-container">
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                        <td>
                        <h3>Give us feedback</h3>
                        <p>We strive to provide the most accurate information. If something doesn’t look right or you have a question, we’d love to hear from you.</p>
                        <Button className="contact-us" onClick={handleContactUsClick}>
                          Contact us
                        </Button>
                        </td>
                        <td className="right-align">
                          <img alt="location" className="resources-table-image" src={ResourceIcon} />
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={isActive ? 'form-wrapper open' : 'form-wrapper'}>
                <FormFields form={formData} />
              </div>
            </div>
          </Stack>
          </Grid>

          {/* <ResourcesSection config={resultPageConfig} /> */}
          <Footer config={resultPageConfig.footer} />
        </>
      )}
    </div>
  );
};

const SchoolDistrictDataTable = ({
  schoolDistricts,
  config,
}: {
  schoolDistricts: BallotReadyData[] | undefined;
  config: ResultPageConfig;
}) => {
  const [activeSchoolDistrict, setActiveSchoolDistrict] = useState<
    BallotReadyData | undefined
  >(schoolDistricts?.[0]);

  const isTabletOrDesktop = useMediaQuery({
    minWidth: 1014,
  });

  useEffect(() => {
    setActiveSchoolDistrict(schoolDistricts?.[0]);
  }, [schoolDistricts]);

  if (isTabletOrDesktop) {
    return desktopAndTabletSchoolDistrictResults(
      config,
      schoolDistricts,
      activeSchoolDistrict,
      setActiveSchoolDistrict,
    );
  }

  return mobileSchoolDistrictResults(
    config,
    schoolDistricts,
    activeSchoolDistrict,
    setActiveSchoolDistrict,
  );
};

const mobileSchoolDistrictResults = (
  config: ResultPageConfig,
  schoolDistricts: BallotReadyData[] | undefined,
  activeSchoolDistrict: BallotReadyData | undefined,
  setActiveSchoolDistrict: (district: BallotReadyData) => void,
) => {
  const [expandedGeoId, setExpandedGeoId] = useState(
    activeSchoolDistrict?.geoId,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setExpandedGeoId(activeSchoolDistrict?.geoId);
  }, [activeSchoolDistrict]);

  const handleDistrictAccordionChange = (district: BallotReadyData) => {
    trackEvent(EventType.AccordionClick, {
      title: "District",
      district: district.name,
    });
    if (district.geoId !== expandedGeoId) {
      setExpandedGeoId(district.geoId);
      setActiveSchoolDistrict(district);
    }
  };

  const handleEmailIconClicked = (
    event: React.MouseEvent<HTMLElement>,
    email: string,
  ) => {
    trackEvent(EventType.CopyEmailClick, { person: "Superintendent", email });
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTimeout(() => setAnchorEl(null), 1000);
    navigator.clipboard.writeText(email);
  };

  return (
    <Grid container className="results-grid-container">
      <Stack className="district-section-container">
        <DistrictSection config={config} />

        {schoolDistricts && schoolDistricts?.length > 0 && (
          <div className="district-card-header">
            <span className="district-card-header-result-count">
              {schoolDistricts.length} Search{" "}
              {schoolDistricts.length === 1 ? "Result" : "Results"}
            </span>
            <span className="district-card-header-label">
              Select result below to view details
            </span>
          </div>
        )}

        {schoolDistricts?.map((district) => (
          <Accordion
            key={district.geoId}
            className="district-accordion"
            expanded={expandedGeoId === district.geoId}
          >
            <AccordionSummary
              expandIcon={
                <div className="district-container-summary-icon">
                  <ExpandMoreIcon className="expand-more-button" />
                </div>
              }
              className="district-container-summary"
              onClick={() => handleDistrictAccordionChange(district)}
            >
              <Stack direction="column" spacing={1}>
                <h3
                  style={
                    expandedGeoId === district.geoId
                      ? {
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }
                      : {}
                  }
                  className="district-title"
                >
                  {district.name}
                </h3>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" spacing={1} key={district.name}>
                <div className="district-section-district-info-container">
                  {superintendentName(district) && (
                    <div className="district-section-district-info">
                      <div className="district-superintendent-container">
                        <header>
                          <div className="district-section-titles">
                            Superintendent
                          </div>
                          <h4>{superintendentName(district)}</h4>
                        </header>

                        {district.districtDetails
                          ?.superintendentEmailAddress && (
                          <>
                            <Button
                              className="email-icon"
                              data-testid="email-icon"
                              sx={{ padding: "0px" }}
                              onClick={(event) =>
                                handleEmailIconClicked(
                                  event,
                                  district.districtDetails
                                    ?.superintendentEmailAddress as string,
                                )
                              }
                            >
                              Email
                              <CopyIcon />
                            </Button>
                            <Popover
                              classes={{ paper: "MuiPopover-paper" }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              anchorEl={anchorEl}
                              open={!!anchorEl}
                            >
                              Email Copied to Clipboard
                            </Popover>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {district.subDistrictName && (
                    <div className="district-section-district-info">
                      <div className="district-section-titles">Subdistrict</div>
                      <h4>{district.subDistrictName}</h4>
                    </div>
                  )}

                  <DistrictWebsiteLink
                    url={district?.districtDetails?.schoolBoardUrl}
                  />
                </div>
                <MeetingAccordion
                  meetingInfo={district?.districtDetails}
                  districtUrl={district?.districtDetails?.schoolBoardUrl}
                />
                <BoardMembersAccordion
                  boardMembers={district?.boardMembers || []}
                  boardMemberLastUpdatedAt={district?.boardMemberLastUpdatedAt}
                  districtUrl={district?.districtDetails?.schoolBoardUrl}
                />
                <ElectionsAccordion
                  electionInfo={district?.electionInfo}
                  districtUrl={district?.districtDetails?.schoolBoardUrl}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Grid>
  );
};

const desktopAndTabletSchoolDistrictResults = (
  config: ResultPageConfig,
  schoolDistricts: BallotReadyData[] | undefined,
  activeSchoolDistrict: BallotReadyData | undefined,
  setActiveSchoolDistrict: (district: BallotReadyData) => void,
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [expandedGeoId, setExpandedGeoId] = useState(
    activeSchoolDistrict?.geoId,
  );

  useEffect(() => {
    setExpandedGeoId(activeSchoolDistrict?.geoId);
  }, [activeSchoolDistrict]);

  const handleDistrictAccordionChange = (district: BallotReadyData) => {
    trackEvent(EventType.AccordionClick, {
      title: "District",
      district: district.name,
    });
    if (district.geoId !== expandedGeoId) {
      setExpandedGeoId(district.geoId);
      setActiveSchoolDistrict(district);
    }
  };

  const handleEmailIconClicked = (
    event: React.MouseEvent<HTMLElement>,
    email: string,
  ) => {
    trackEvent(EventType.CopyEmailClick, { person: "Superintendent", email });
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTimeout(() => setAnchorEl(null), 1000);
    navigator.clipboard.writeText(email);
  };

  return (
    <Grid container className="results-grid-container">
      <Grid xs={12} md={6} lg={6} item style={{ zIndex: 10 }}>
        <Stack className="district-section-container">
          <DistrictSection config={config} />

          <div className="district-card-extra-information">
            {schoolDistricts && schoolDistricts?.length > 0 && (
              <div className="district-card-header">
                <span className="district-card-header-result-count">
                  {schoolDistricts.length} Search{" "}
                  {schoolDistricts.length === 1 ? "Result" : "Results"}
                </span>
                <span className="district-card-header-label">
                  Select result below to view details
                </span>
              </div>
            )}

            {schoolDistricts?.map((district) => (
              <Accordion
                key={district.geoId}
                className="district-container"
                style={
                  expandedGeoId === district.geoId
                    ? { background: "white" }
                    : {
                        background: "transparent",
                        boxShadow: "none",
                        border: "1px solid #145758",
                        borderRadius: "10px",
                      }
                }
                expanded={expandedGeoId === district.geoId}
              >
                <AccordionSummary
                  expandIcon={
                    <div className="district-container-summary-icon">
                      <ExpandMoreIcon className="expand-more-button" />
                    </div>
                  }
                  onClick={() => handleDistrictAccordionChange(district)}
                  className="district-container-summary"
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <GradCap />
                    <header>
                      <h3
                        style={expandedGeoId === district.geoId ? {} : {}}
                        className="district-title"
                      >
                        {district.name}
                      </h3>
                      <p className="district-container-summary-address">
                        {district?.districtDetails?.addressLine1}
                      </p>
                    </header>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    direction="column"
                    spacing={1}
                    style={{ textAlign: "left", padding: "0px 16px" }}
                  >
                    {superintendentName(district) && (
                      <div className="district-superintendent-container">
                        <header>
                          <div className="district-section-titles">
                            Superintendent
                          </div>
                          <h4>{superintendentName(district)}</h4>
                        </header>

                        {district.districtDetails
                          ?.superintendentEmailAddress && (
                          <>
                            <Button
                              className="email-icon"
                              data-testid="email-icon"
                              sx={{ padding: "0px" }}
                              onClick={(event) =>
                                handleEmailIconClicked(
                                  event,
                                  district.districtDetails
                                    ?.superintendentEmailAddress as string,
                                )
                              }
                            >
                              Email
                              <CopyIcon />
                            </Button>
                            <Popover
                              classes={{ paper: "MuiPopover-paper" }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              anchorEl={anchorEl}
                              open={!!anchorEl}
                            >
                              Email Copied to Clipboard
                            </Popover>
                          </>
                        )}
                      </div>
                    )}

                    {district.subDistrictName && (
                      <div>
                        <div className="district-section-titles">
                          Subdistrict
                        </div>
                        <h4>{district.subDistrictName}</h4>
                      </div>
                    )}

                    <DistrictWebsiteLink
                      url={district?.districtDetails?.schoolBoardUrl}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Stack>
      </Grid>

      <Grid item md={6} lg={6} className="accordion-container">
        <Stack>
          <MeetingAccordion
            meetingInfo={activeSchoolDistrict?.districtDetails}
            districtUrl={activeSchoolDistrict?.districtDetails?.schoolBoardUrl}
          />
          <BoardMembersAccordion
            boardMembers={activeSchoolDistrict?.boardMembers || []}
            boardMemberLastUpdatedAt={
              activeSchoolDistrict?.boardMemberLastUpdatedAt
            }
            districtUrl={activeSchoolDistrict?.districtDetails?.schoolBoardUrl}
          />

          <ElectionsAccordion
            electionInfo={activeSchoolDistrict?.electionInfo}
            districtUrl={activeSchoolDistrict?.districtDetails?.schoolBoardUrl}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const DistrictWebsiteLink = ({ url }: { url: string | undefined }) => {
  if (!url) {
    return null;
  }

  return (
    <div className="district-website-link-container">
      <Link href={url} className="district-website-link">
        <>
          <AnchorIcon sx={{ color: "primary" }}></AnchorIcon>
          <span>{url}</span>
        </>
      </Link>
    </div>
  );
};

const superintendentName = (district: BallotReadyData) => {
  if (
    !district.districtDetails ||
    !district.districtDetails.superintendentFirstName ||
    !district.districtDetails.superintendentLastName
  ) {
    return null;
  }

  return `${district.districtDetails.superintendentFirstName} ${district.districtDetails.superintendentLastName}`;
};
