import React from "react";
import "./HookContentSection.css";
import Grid from "@mui/system/Unstable_Grid";
import { Stack } from "@mui/material";
import { HomePageConfig } from "../../lib/types/cmsConfigs";
import mixpanel from "mixpanel-browser";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

interface HookContentSectionProps {
  config: HomePageConfig;
}

const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
};

export const HookContentSection = ({ config }: HookContentSectionProps) => {

  const CarouselClick = () => {
    const trackText = 'Home carousel';
    mixpanel.track("internal_clicks",{
        "target_url": '/',
        "target_text": trackText,
        }
    );
  }

  return (
    <Stack className="content-section-container">
      <Grid container>
        <Grid xs={12}>
          <section>
            <header>
              <h2>{config.hookContent.heading}</h2>
              <svg
                className="hook-content-circle"
                width="281"
                height="105"
                viewBox="0 0 281 105"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M108.572 12.87C199.255 4.16175 277.7 22.806 278.698 47.9095C279.695 73.0131 218.663 95.8203 142.38 98.8508C66.096 101.881 3.44744 83.9877 2.45014 58.8841C1.49656 34.881 60.9991 7.41583 153.325 5.23713"
                  stroke="#83FBA3"
                  strokeWidth="1.5"
                />
                <path
                  d="M176.307 89.1426C85.6243 97.8509 3.36507 81.8 2.36778 56.6964C1.37048 31.5929 62.4021 8.78569 138.686 5.75515C214.969 2.7246 277.618 20.6183 278.615 45.7219C279.569 69.7249 220.066 97.1901 127.741 99.3688"
                  stroke="#83FBA3"
                  strokeWidth="1.5"
                />
              </svg>
            </header>
            <p>{config.hookContent.body}</p>
            <div role="button" tabIndex={0} onClick={CarouselClick} onKeyDown={CarouselClick}>
              {config.hookContent.carousel.length && (
                <Slider {...settings}>
                  {config.hookContent.carousel.map((item, index) => (
                    <div key={`carousel-item-${index}`}>
                      <img alt="Carousel item" src={item?.imageUrl} />
                      <h3>{item?.text}</h3>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </section>
        </Grid>
      </Grid>
    </Stack>
  );
};
